/* @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600&display=swap"); */

*body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
  /* overflow: hidden; */
}
a,
a:hover {
  text-decoration: none !important;
}
:root {
  --primary-color: #fff;
  --secondary-color: #343434;
  --Red-color: #cb343b;
  --Red-color-light: #e8535b;
  --gray-color: #343434;
  --pink-color: #ffcdbd;
  --light-pink: #ffe3da;
  --grayish-color: #d0e2d3;
  --light-grayish: #e9ffec;
  --light-gray600: #f2f2f2;
  --pink900: #ffa8ac;
  --gray800: #4a4a4a;
}
.color-red {
  color: var(--Red-color);
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.box-end {
  display: flex;
  justify-content: flex-end;
}
.box-start {
  display: flex;
  justify-content: flex-start;
}
.text-center {
  text-align: center;
}
.bg-gray600 {
  background-color: var(--light-gray600);
}
.no-padding {
  padding-left: 0px;
  padding-right: 0px;
}

.base-paragraph {
  text-align: justify;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: var(--gray800);
}
.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

/* Main Section CSS  */
.main-center {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.uparrow {
  width: 50px;
}
.arrow-border {
  transition: 0.3s;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  width: 80px;
  height: 80px;
  background-color: var(--Red-color);
  color: var(--primary-color);
  border-radius: 50%;
  border: 3px solid var(--Red-color);
  font-weight: 600;
  font-size: 45px;
  line-height: 24px;
  letter-spacing: -1px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.arrow-border:hover {
  background-color: white;
  border: 3px solid black;
  color: black;
}
.loader-section {
  background-color: var(--primary-color);
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1002;
}

.loader {
  border: 6px solid #f2989c;
  border-radius: 50%;
  border-top: 6px solid var(--Red-color);
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  text-align: center;
  background-color: var(--primary-color);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.loader-2 {
  position: relative;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  border-radius: 50%;
  z-index: 2;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
ul {
  padding-left: 15px;
}
.main-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 72px;
  letter-spacing: -3px;
}
.img-vector {
  position: absolute;
  top: 84%;
}
.landing-hero-img {
  position: absolute;
  top: 0px;
  right: 0px;
  max-height: 750px;
}
.landing-family {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-paragraph {
  margin-top: 24px;
  margin-bottom: 0;
}
.main-btn {
  margin-top: 72px;
  padding: 13px 38px;
  background-color: var(--Red-color);
  color: var(--primary-color) !important;
  border-radius: 10px;
  border: 1px solid var(--Red-color);
  outline: none;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-align: center;
  letter-spacing: -1px;
  transition: 0.3s;
  cursor: pointer;
}
.main-btn:hover {
  text-decoration: none;
  background-color: var(--primary-color);
  color: var(--Red-color) !important;
}
.home-cta {
  margin-bottom: 40px;
}
.background-main {
  background-image: url("../src/assets/banner-bg.png");
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  position: relative;
  padding: 120px 0px;
}

/* Head Section CSS  */
.background-head {
  background-image: url("../src/assets/ceo-bg.png");
  width: 100%;
  padding-bottom: 108px;
  background-repeat: no-repeat;
  background-size: cover;
}

.head-center {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.head-heading1 {
  margin-bottom: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  letter-spacing: -2px;
  color: var(--Red-color);
}

.head-heading2 {
  margin-bottom: 16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -1px;
  color: var(--gray-color);
}
.head-paragraph {
  text-align: justify;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: var(--secondary-color);
  position: relative;
}
.ellipse-text {
  height: 192px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.non-ellipse-text {
  height: auto;
  overflow: auto;
  text-overflow: unset;
}
.span-text {
  background-color: var(--primary-color);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: var(--Red-color);
  padding: 0px 5px;
  border: none;
  outline: none;
}
.span-ellipse {
  position: absolute;
  top: 168px;
  right: 0px;
}
.span-non-ellipse {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.margin-head {
  padding-right: 200px;
}

/* Mission Section CSS */
.mission-background {
  background-color: var(--gray-color);
  background-image: url("../src/assets/Group.png");
  padding-top: 96px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
}
.mission-heading1 {
  padding-bottom: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;

  letter-spacing: -1px;

  color: #ffffff;
}
.mission-heading2 {
  padding-bottom: 16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;

  letter-spacing: -2px;

  color: var(--primary-color);
}
.mission-paragraph {
  width: 85%;
  color: var(--primary-color);
}
.mission-alignment {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.mission-heading3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -2px;
  color: var(--primary-color);
}

/* Box Detail Section */

.box-detail {
  max-width: 300px;
  margin-bottom: 15px;
  width: 100%;
  height: 267px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
  border-radius: 30px;
  background-color: #464646;
  padding: 40px 30px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}
.box-margin {
  margin-top: -15px;
}
.rotate {
  margin-left: -34px;
  transform: rotate(8.86deg);
}

/* Product Section CSS */
.product-image-section {
  background: rgba(255, 255, 255, 0.5);
}
.product-img {
  width: 100%;
  height: 100%;
}
.product-h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -1px;

  color: var(--secondary-color);
}
.product-paragraph {
  padding-bottom: 8px;
  margin-bottom: 0px;
  color: var(--secondary-color);
  text-align: justify;
}
.exec-team {
  margin-top: 16px;
}
.product-h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;

  letter-spacing: -2px;

  color: var(--Red-color);
}

.dot {
  position: absolute;
  color: var(--secondary-color);
}
/* .product-paragraph {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.5px;
  color: var(--secondary-color);
} */
.product-overlay {
  background-color: rgba(0, 0, 0, 0.16);
  top: 0px;
  position: fixed;
  left: 0px;
  width: 100%;
  z-index: 1199;
  display: none;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
.product-see-more {
  background-color: white;
  font-size: 14px;
  font-weight: 500;
  color: var(--Red-color);
  cursor: pointer;
  margin-bottom: 16px;
  letter-spacing: -0.5px;
  font-family: "Poppins", sans-serif;
}
.product-spacing {
  margin-top: 80px;
}
/* //product section */
.product-box-section {
  margin-bottom: 48px;
}
.pink-bg {
  background: var(--pink-color);
}
.light-pink-bg {
  background-color: var(--light-pink);
}
.pink-border {
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #ffcdbd;
}
.light-green-bg {
  background: #e9ffec;
}
.green-border {
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #d0e2d3;
}
.herbal-border {
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #ffc48d;
}
.green-bg {
  background-color: #d0e2d3;
}
.herbal-bg {
  background-color: #ffe3d4;
}
.light-herbal-bg {
  background-color: #ffc48d;
}
.product-section {
  display: flex;
  width: 100%;
  height: 390px;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  position: relative;
}
.circle-sm-right {
  top: 6%;
  right: 30%;
  position: absolute;
}
.circle-sm-left {
  top: 6%;
  left: 30%;
  position: absolute;
}
.circle-sm {
  border-radius: 50%;
  width: 17px;
  height: 17px;
}
.circle-lg-right {
  left: 6%;
  top: 50%;
  position: absolute;
}
.circle-lg-left {
  right: 6%;
  top: 50%;
  position: absolute;
}
.circle-lg {
  width: 31px;
  height: 31px;
  border-radius: 50%;
}
.circle-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 320px;
  height: 320px;
  border-radius: 50%;
}
.cirlce-box .product-img {
  width: 100%;
  height: 360px;
}
.product-center {
  display: flex;
  align-items: center;
}
.right-product-info {
  right: 10%;
  z-index: 1;
  position: absolute;
}
.left-product-info {
  left: 10%;
  z-index: 1;
  position: absolute;
}
.product-info-box {
  backdrop-filter: blur(40px);
  border-radius: 30px;
  padding: 40px;
  width: 100%;
}
.product-info-box-overlay {
  backdrop-filter: unset !important;
  background-color: white !important;
}
.product-info-box .product-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  display: flex;
  align-items: center;
  letter-spacing: -2px;
  color: var(--gray-color);
}
.product-info-box .product-paragraph {
  display: flex;
  align-items: center;
  color: var(--secondary-color);
  margin-bottom: 8px;
}
.hide-paragraph {
  display: none !important;
}

.view-product-btn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 13px 13px;
  text-align: center;
  letter-spacing: -1px;
  color: var(--primary-color);
  width: 100%;
  background: var(--Red-color);
  border: 1px solid var(--Red-color);
  border-radius: 10px;
  transition: 0.3s;
}
.view-product-btn:hover {
  color: var(--Red-color);
  background-color: var(--primary-color);
}

.product-section3 {
  top: 7%;
  left: 2%;
  position: absolute;
  width: 525px;
  height: 334px;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #ffcdbd;
  backdrop-filter: blur(40px);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.product__padding {
  padding-top: 102px;
}
.product-section4 {
  display: flex;
  width: 648px;
  height: 390px;
  background: var(--grayish-color);
  border-radius: 30px;
  align-items: center;
  justify-content: center;
}

.circle4 {
  top: -40%;
  position: relative;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  background-color: var(--light-grayish);
}
.circle5 {
  left: 12%;
  top: -7%;
  position: relative;
  width: 31px;
  height: 31px;
  border-radius: 50%;
  background-color: var(--light-grayish);
}
.circle6 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 324px;
  height: 324px;
  border-radius: 50%;
  background-color: var(--light-grayish);
}
.align-end {
  margin-top: 79px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* Arrow in css */
.arrow {
  position: absolute;
  border: solid var(--primary-color);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 5px;
  top: 19.8%;
}
.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

/* History Section CSS */

.history-bg {
  /* margin-top: 64px; */
  background-color: var(--gray-color);
  background-image: url("../src/assets/history-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-bottom: 88px;
}
.adjust-text {
  padding-top: 88px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  position: sticky;
  top: 0px;
}

.history-heading1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;

  letter-spacing: -1px;

  color: var(--primary-color);
}
.history-heading2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  letter-spacing: -2px;

  color: var(--primary-color);
}
.history-para {
  text-align: justify;
  color: var(--primary-color);
}

.vl {
  width: 0px;
  height: 250px;
  border: 2px solid var(--primary-color);
}
.align-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.right-arrow {
  color: var(--primary-color);
  transform: rotate(90deg);
}
.hist-main-div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.hist-number {
  margin-top: 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 120px;

  text-align: center;
  letter-spacing: -4px;

  color: var(--primary-color);
}
.hist-exp {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.5px;

  color: var(--primary-color);
}

/* Policy Section Css */

.policy-bg {
  padding: 80px 0px;
  background-image: url("../src/assets/Policy-bg.png");
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.rounded-pol {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: #ffffff;

  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
}
.policy-heading1 {
  margin-top: 24px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -2px;
  color: #cb343b;
}
.policy-paragraph {
  width: 85%;
  text-align: center;
  color: var(--secondary-color);
}

.align-content {
  margin-top: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* Team Section CSS */
.team-section-spacing {
  padding-top: 104px;
  padding-bottom: 64px;
}
.adjust-box {
  padding-top: 30px;
  text-align: center;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background: var(--primary-color);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  margin-bottom: 30px;
}
.dir-img {
  width: 130px;
}
.dir-name {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -1.5px;
  color: var(--Red-color);
  margin: 16px 0px 8px 0px;
}
.dir-designation {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
  margin-bottom: 8px;
  color: var(--gray-color);
}
.text-align {
  margin-left: 30px;
  margin-bottom: 30px;
  margin-right: 30px;
}
.dotted {
  position: absolute;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: var(--Red-color);
  opacity: 0.2;
  position: absolute;
  left: 91%;
  top: 5%;
}
.team-h1 {
  margin-top: 32px;
  margin-bottom: 64px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;

  text-align: center;
  letter-spacing: -2px;

  color: var(--secondary-color);

  opacity: 0.9;
}

.therapeutic-bg {
  background-image: url("/src/assets/therapeutic-bg.svg");
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  padding: 80px 0px;
}

/* PAGR CSR  */

/* HERO SECTION CSS */
.csr {
  padding-top: 80px;
  padding-bottom: 0px;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(50px);
}

.supply-chain-bg {
  padding-top: 80px;
  padding-bottom: 80px;
}
.csr-bg {
  background-image: url("/src/assets/csr-banner-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.img-vector2 {
  position: absolute;
  top: 87%;
}
.csr-img {
  width: 100%;
}
.global-supply-gif {
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
  object-position: center;
  width: 100%;
}
.c-main-paragraph {
  width: 85%;
  margin-top: 34px;
  margin-bottom: 0;
  color: var(--gray800);
}
.c-main-center {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.csr-text-section {
  padding: 60px 0px;
}
.csr-community {
  padding: 0px 0px 80px 0px;
}
/* Description Section OF CSR */
.c-description {
  text-align: justify;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;

  color: #4a4a4a;
}
.c-description-bold {
  font-weight: 600 !important;
}

/* Community Section */
.community-detail {
  padding: 35px;
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
}
.pharma-section {
  margin-bottom: 40px;
}
.community-h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: -1.5px;
  margin-bottom: 0px;
  color: #343434;
}
.pharma-download-text {
  margin-top: 32px;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0px;
}
.pharma-download-btn {
  margin-top: 16px;
}
.pharma-container {
  width: 100%;
  margin-bottom: 24px;
}
.community-pharma-img {
  width: 100%;
  height: 350px;
  object-fit: cover;
  object-position: center;
  border-radius: 8px;
}
.sector-dot {
  color: var(--Red-color);
  position: absolute;
}
.community-paragraph {
  margin-top: 30px;
  letter-spacing: -0.5px;
  color: var(--gray800);
  opacity: 0.8;
}
/* .community-arrow {
  position: relative;
  width: 100px;
  top: 14%;
} */
.community-curved {
  position: absolute;
  top: 14%;
  left: -2%;
}
.community-curve-invert {
  position: absolute;
  top: 14%;
  right: -2%;
  /* transform: scaleX(-1); */
}

/* Contact Section CSS */
.contact-h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;

  letter-spacing: -3px;

  color: #cb343b;
}
.contact-h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;

  color: #000000;
}

.contact-bg {
  background-image: url("../src/assets/contact-bg.png");
  background-repeat: no-repeat;

  background-position: right;
  overflow: hidden;
  height: 100%;
}

.input-class {
  width: 536px;
  height: 76px;

  padding: 12px 20px;
  margin: 8px 0px 9px 0px;
  display: inline-block;

  background: var(--primary-color);
  border: 1px solid #464646;
  border-radius: 10px;
}

.input-email {
  width: 536px;
  height: 76px;

  padding: 12px 20px;
  display: inline-block;

  background: var(--primary-color);
  border: 1px solid #464646;
  border-radius: 10px;
}
.input-message {
  width: 1103px;
  height: 178px;

  background: var(--primary-color);
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #464646;
  border-radius: 10px;
}
.community-btn {
  margin-top: 52px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -1px;
  color: var(--primary-color);
  padding: 13px 38px;
  background: var(--Red-color);
  border-radius: 10px;
  border: 1px solid var(--Red-color);
  transition: 0.3s;
}
.community-btn:hover {
  text-transform: none;
  color: var(--Red-color);
  background-color: var(--primary-color);
}
/* Footer Css */
.footer-bg {
  /* height: 100%; */
  padding-top: 80px;
  background: var(--gray-color);
}
.follow {
  font-family: "Poppins";
  margin-top: 40px;
  margin-bottom: 11px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: var(--primary-color);

  opacity: 0.6;
}
a {
  color: white;
  text-decoration: none;
}
.social-btn {
  /* padding-left: 25px; */
  margin: 0px 5px;
}
/* .justify-start {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  
} */
.display-line {
  display: flex;
  align-self: center;
  justify-content: center;
}
.footer-pinpoint {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  letter-spacing: -1px;
  color: var(--primary-color);
  border-bottom: 2px solid var(--gray-color);
  transition: 0.3s;
  width: max-content;
}
.footer-pinpoint:hover {
  border-bottom: 2px solid var(--primary-color);
  text-decoration: none;
}
/* .align-start {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  height: 240px;
} */
.social-media-icons {
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 32px;
}
.horizontal-line {
  height: 2px;
  width: 100%;
  background: #d9d9d9;
}
.himont-group {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 0px;
  letter-spacing: -0.5px;
  color: #ffffff;
}
.himont-group-line {
  text-decoration: underline;
  transition: 0.3s;
}
.himont-group-line:hover {
  color: var(--Red-color-light);
}
.himont-spacebetween {
  margin: 30px 0px 30px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.display-flex {
  display: flex;
  align-items: center;
}

/* MEDIA OR EVENT SECTION CSS */
.event {
  padding: 80px 0px;
}
.events-img {
  width: 100%;
}
.event-bg {
  padding-top: 106px;
  background-color: var(--primary-color);
  background-image: url("../src/assets/media-bg.png");
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.img-vector3 {
  width: 355px;
  height: 20px;
  left: 26%;
  position: absolute;
  top: 80%;
}
.our-products-paragraph {
  margin-top: 34px;
  margin-bottom: 0;
  text-align: center;
}
.our-products-svg {
  width: auto;
}
.event-paragraph {
  margin-top: 34px;
  margin-bottom: 0;
  text-align: justify;
}
.place-img {
  width: 100%;
}
.place-padding {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 72px 0px 16px 0px;
}
.place-adjust {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 56px 0px 59px;
}
.place-img2 {
  margin-top: 15px;
  width: 100%;
}

/* Media video of youtube section css */
.uplayer-bg {
  height: 744px;
  margin-top: 98px;
  background-color: var(--primary-color);
}

.uplayer-iframe {
  width: 100%;
  border: none;
  height: 547px;
  border-radius: 20px;
}
.uplayer-h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;

  text-align: center;
  letter-spacing: -3px;
  margin-bottom: 54px;
  color: var(--secondary-color);
  opacity: 0.9;
}

/* Media Follow section css */
.follow-bg {
  padding: 80px 0px;
  background-color: #252525;
  background-image: url("../src/assets/contact-follow-us-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
.follow-h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -2px;
  color: var(--primary-color);
}

.follow-h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  /* text-align: center; */
  letter-spacing: -0.5px;
  justify-content: center;

  color: var(--primary-color);

  opacity: 0.6;
}
.adjust-block {
  width: 94px;
  height: 94px;
  border-radius: 50%;
  border: 2px solid rgb(12, 12, 12);
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(12, 12, 12);
  transition: 0.3s;
}
.adjust-block:hover {
  border-color: white;
}
.adjust-social {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 40px;
  flex-wrap: wrap;
}
.event-h1 {
  margin-bottom: 48px;
  margin-top: 16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;

  letter-spacing: -1px;

  color: var(--gray-color);
}
.justify-block {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
}
.our-products-page-img {
  width: 100%;
}
.testimonial-bg {
  display: none;
  background-color: var(--light-gray600);
  padding: 88px 0px 64px 0px;
}
.testimonial-area {
  padding-top: 56px;
  overflow-x: scroll;
  padding-bottom: 20px;
}
.testimonials-row {
  flex-wrap: nowrap;
}
.testimonials {
  background-color: var(--primary-color);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  padding: 30px 12px;
  width: 100%;
  margin-bottom: 16px;
}
.testimonials .image-margin-bottom {
  margin-bottom: 104px;
}
.testimonials .image-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.testimonials .image-box .user-img {
  width: 100px;
  height: 100px;
}
.testimonials .image-box .columns {
  background-color: var(--pink900);
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  right: 0px;
  border-radius: 50%;
}
.testimonials .image-box .divider {
  height: 2px;
  width: 66px;
  border-radius: 2px;
  background-color: var(--Red-color);
}
.testimonials .review-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.5px;
  margin-bottom: 16px;
  margin-top: 16px;
  text-align: center;
  color: var(--secondary-color);
}
.testimonials .reviewer-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.5px;
  color: var(--secondary-color);
  margin-bottom: 0px;
  text-align: center;
}
.contact-section {
  padding: 64px 0px;
}
.contact-box {
  background-color: var(--primary-color);
  border-radius: 10px;
  border: 1px solid var(--Red-color);
  padding: 48px 40px 24px 40px;
}
.form {
  margin-top: 24px;
  margin-bottom: 64px;
}
.form .input-container {
  margin: 12px 0px;
}
.form .input-container .main-label-class {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -1px;
  margin-bottom: 8px;
}
.form .input-container .main-input-class {
  width: 100%;
  background: var(--primary-color);
  padding: 19px 20px;
  border: 1px solid #464646;
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
}
.text-area-class {
  height: 110px;
}
.contact-send-message {
  margin-top: 12px;
  width: 180px;
  padding: 16px 36px;
  background-color: var(--Red-color);
  color: var(--primary-color);
  border-radius: 10px;
  border: none;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -1px;
}
.more-box {
  display: flex;
  justify-content: space-between;
}
.align-more {
  display: flex;
  align-items: center;
}
.more-box .contact-area {
  display: flex;
  align-items: center;
}
.more-box .contact-area:hover {
  text-decoration: none;
}
.more-box .contact-icon {
  margin-right: 16px;
  width: 20px;
  height: 20px;
}
.more-box .contact-info {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: var(--secondary-color);
  margin-bottom: 0px;
}
.contact-img-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-img {
  width: 100%;
}

/* /
/
/
/
/
/
Company Profile */

.company-profile {
  background-image: url("./assets/company-profile-bg.png");
  height: 669px;
  background-size: cover;
  background-position: center;
}
.cp-height-100 {
  height: 100%;
}
.cp-center {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.timeline {
  padding: 80px 0px;
}
.timeline-spacing {
  margin-top: 64px;
}
.timeline-full {
  width: 100%;
  position: relative;
}
.timeline-end {
  display: flex;
  justify-content: flex-end;
}
.timeline-center-date {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.timeline-founded {
  background-color: var(--gray-color);
  padding: 18px 40px;
  width: max-content;
  border-radius: 30px;
}
.timeline-founded .date {
  text-align: center;
  color: var(--primary-color);
  margin-bottom: 0px;
}
.timeline-box {
  background: var(--primary-color);
  padding: 24px 24px 32px 24px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  width: 85%;
}
.timeline-padding {
  position: relative;
}
.timeline-padding :first-child {
  padding-top: 40px;
}
.timeline .year {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.5px;
  color: var(--gray-color);
  opacity: 0.5;
  margin-bottom: 8px;
  padding-top: 0 !important;
}
.timeline .milestone {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -2px;
  color: var(--gray-color);
  margin-bottom: 8px;
}
.timeline .description {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.5px;
  color: var(--gray-color);
  margin-bottom: 0px;
}

.timeline .spot {
  position: absolute;
  background: #ff7c82;
  border: 1px solid #fff4f4;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  top: 45%;
  z-index: 2;
}
.spot-left {
  right: -25px;
}
.spot-right {
  left: -25px;
}
.timeline-border {
  position: relative;
}
.timeline-border::before {
  content: "";
  display: block;
  width: 6px;
  position: absolute;
  background-color: #464646;
  z-index: 0;
  left: 50%;
  border-radius: 4px;
  height: calc(100% + 40px);
  transform: translate(-50%);
  opacity: 0.2;
}
.company-image-section {
  padding: 104px 0px 64px 0px;
}
.company-image-spacing {
  margin-top: 80px;
  width: 100%;
}
.company-images {
  width: 100%;
  margin-bottom: 16px;
}
.company-location-section {
  padding: 104px 0px 64px 0px;
}
.loading-circle {
  position: absolute;
  top: 36%;
  right: 22%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-circle .himont-circle,
.loading-circle .himont-loader {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-circle .ping {
  -webkit-animation: ping 1.8s ease-in-out infinite both;
  animation: ping 1.8s ease-in-out infinite both;
}
.himont-loader {
  width: 150px;
  height: 150px;
  background-color: var(--Red-color-light);
}
.loading-circle .himont-circle {
  background-color: var(--primary-color);
  border: 1px solid var(--Red-color-light);
  position: absolute;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

@-webkit-keyframes ping {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0.8;
  }
  80% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(2.2);
    transform: scale(2.2);
    opacity: 0;
  }
}
@keyframes ping {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0.8;
  }
  80% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(2.2);
    transform: scale(2.2);
    opacity: 0;
  }
}
.map-text-box {
  padding: 10px 20px;
  border-radius: 2px;
  position: absolute;
  top: calc(36% + 145px);
  right: 23.4%;
  background-color: var(--primary-color);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
}
.map-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.5px;
  color: var(--gray-color);
  margin-bottom: 0px;
}

.benefits-box {
  background: #ffffff;
  width: 100%;

  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
  padding: 20px 24px;
  border-radius: 10px;
  margin-bottom: 16px;
}

.benefits-box .benefit-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */
  text-align: center;
  letter-spacing: -1px;
  margin-bottom: 16px;
  /* Black/black700 */

  color: #343434;
}

/* ABOUT US PAGE CSS */

/* About Hero Section Css */
.about-bg {
  margin-bottom: 70px;
  margin-top: 115px;
  background-color: var(--gray-color);
  background-image: url("../src/assets/media-bg.png");
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.about-video {
  margin-top: 40px;
}
.img-vector4 {
  width: 217px;
  height: 20px;
  left: 35%;
  position: absolute;
  top: 86%;
}
.about-hero-img {
  width: 100%;
  margin-top: 32px;
}
.group-margin {
  margin-bottom: 16px;
}
.achievements {
  background-color: var(--primary-color);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  width: 100%;
  margin-bottom: 40px;
}
.achievement-margin {
  margin-top: 40px;
}
.achievement-box {
  padding: 32px 24px;
}
.achievement-box .title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  margin-top: 24px;
  text-align: left;
  letter-spacing: -1px;
  margin-bottom: 16px;
  color: var(--gray-color);
}
.achievement-box .name {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  text-align: left;
  letter-spacing: -1px;
  margin-bottom: 16px;
  color: var(--gray-color);
}
.achievement-box .job-title {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  text-align: left;
  letter-spacing: -1px;
  margin-bottom: 0px;
  color: var(--gray-color);
  opacity: 0.6;
}
/* Career Page Css */
.img-underline {
  position: absolute;
  top: 86%;
  width: 32%;
  height: 13%;
}
.border-bottom {
  border-bottom: 1px solid #adadad;
}
.role-bg {
  height: 100%;
  background-image: url("./assets/Role-bg.png");
  background-repeat: no-repeat;
  margin-bottom: 80px;
  background-position: right;
}
.role-h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  margin: 0;
  text-align: center;
  letter-spacing: -2px;

  color: var(--gray-color);
}
.padding-lr {
  padding-left: 6px;
  padding-right: 6px;
  padding: 10px 0px;
}
.group-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}
.research-section {
  padding-top: 104px;
  padding-bottom: 80px;
  background-image: url("/src/assets/research-banner-bg.png");
  background-position: top;
  background-size: cover;
}
.research-second-section {
  padding-top: 80px;
}
.research-box {
  position: relative;
}
.research-box .research-img {
  border: 1px solid var(--gray-color);
  border-radius: 20px;
  width: 100%;
  z-index: 2;
  position: relative;
  object-fit: cover;
  max-height: 350px;
  object-position: center;
}
.research-text-section {
  padding: 120px 0px;
}
.research-frame {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 20px;
  top: 15px;
  border: 1px solid var(--gray-color);
  border-radius: 20px;
  z-index: 1;
}

/* JOB ROLE CSS */
.job-bg {
  padding-left: 48px;
  padding-right: 48px;
  padding-top: 35px;
  position: absolute;
  height: 100%;
  top: 0%;
  right: 0%;
  width: 50%;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 20px 0px 0px 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.5px;
  color: #343434;
}
.close-btn {
  box-sizing: border-box;
  width: 70px;
  height: 24px;

  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0.5px solid #464646;
  border-radius: 12px;
}
.cross {
  width: 13px;
  height: 17px;
}
.job-h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;

  letter-spacing: -2px;

  color: var(--gray-color);
}
.apply-btn {
  width: 180px;
  cursor: pointer;
  height: 50px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-top: 40px;
  text-align: center;
  letter-spacing: -1px;
  border: 1px solid var(--Red-color);
  color: var(--primary-color);
  background: var(--Red-color);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}
.apply-btn:hover {
  color: var(--Red-color);
  background-color: var(--primary-color);
}

.overview-h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: -0.5px;

  color: #343434;
}
.overview-paragraph {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
  margin-bottom: 8px;

  color: #343434;
}

.responsibility-h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: -0.5px;

  color: var(--gray-color);
}
.responsibility-list {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;

  color: var(--gray-color);
}
.pointer {
  cursor: pointer;
}
.job-center {
  padding: 29px;
}

.first-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  margin-bottom: 25px;
}

/* APPLY SECTION cSS */

.apply-now {
  margin-bottom: 8px;
  font-family: "Poppins";
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 700;
  margin-top: 133px;
  font-size: 14px;
  line-height: 21px;

  letter-spacing: -1px;

  color: var(--gray-color);
}
.vacancy {
  font-family: "Poppins";
  margin-bottom: 58px;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;

  letter-spacing: -2px;

  color: #cb343b;
}

.black-dot {
  color: var(--gray-color);
}
.apply-input {
  margin-bottom: 32px;
  box-sizing: border-box;
  padding-left: 20px;
  width: 536px;
  height: 76px;

  background: var(--primary-color);
  border: 1px solid #464646;
  border-radius: 10px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: -0.5px;

  color: var(--gray-color);

  opacity: 0.5;
}

.apply-lable {
  margin-bottom: 16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: -0.5px;

  /* Black/black700 */

  color: #343434;
}
.asterik {
  color: var(--Red-color);
}
.input-resume {
  width: 1088px;
  height: 238px;

  background: #f2f2f2;
  border-radius: 20px;
}
.submit-btn {
  border: none;
  width: 180px;
  height: 50px;

  background: var(--Red-color);

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;
  letter-spacing: -1px;

  color: var(--primary-color);
  border-radius: 10px;
}
.submit {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 65px;
}

.note-bg {
  background-color: #f2f2f2;
  height: 100%;
  background-repeat: no-repeat;
}

.note-h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -2px;
  margin-top: 80px;
  color: var(--gray-color);
}

.note-h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  /* text-align: center; */
  letter-spacing: -0.5px;
  justify-content: center;

  color: var(--gray-color);

  opacity: 0.6;
}
.note-block {
  width: 94px;
  height: 94px;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-color);
}
.note-block:hover {
  border: 2px solid #ff5d65;
}
.overlay-backdrop {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  transition: 0.3s ease-in;
  width: 100%;
}
.background {
  background-color: rgba(0, 0, 0, 0.5);
}
.open-animation {
  opacity: 1;
  z-index: 1002;
}
.close-animation {
  opacity: 1;
  z-index: -1;
}
.overlay {
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  /* -webkit-transform: translateX(100%); */
  /* transform: translateX(100%); */
  width: 100%;
  z-index: 1003;
}
.overlay-events {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  transition: 0.3s ease-in;
  width: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
}
.open-events {
  opacity: 1;
}
.close-events {
  opacity: 0;
  z-index: -1;
}
.remove-animation {
  transform: translateX(100%);
  transition: transform 0.3s ease-in 0s;
  transition: transform 0.3s ease-in 0s, -webkit-transform 0.3s ease-in 0s;
}
.add-animation {
  transform: translateX(0);
  transition: transform 0.3s ease-out 0s;
  transition: transform 0.3s ease-out 0s, -webkit-transform 0.3s ease-out 0s;
}

/* TOOLBAR CSS  */
.toolbar {
  position: sticky;
  background-color: var(--primary-color);
  width: 100%;
  top: 0;
  height: 90px;
  padding: 0 45px;
  left: 0;
  z-index: 1000;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
}
.toolbar__navigation {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
}
/* @media (max-width: 768px) {
  .toolbar__navigation {
    margin-left: -120px;
  }
} */

.toolbar-logo a {
  text-decoration: none;
  color: white;
}
.toolbar-logo a img {
  width: 75px;
  height: 75px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.spacer {
  flex: 1;
}

.toolbar-logo {
  margin: 0.5rem;
}
.toolbar_navigation-items a {
  color: #000000;
  text-decoration: none;
}

.toolbar_navigation-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}
.toolbar_navigation-items li {
  padding: 0 15.59px;
}

.toggle-button {
  justify-content: space-around;
  display: flex;
  width: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  flex-direction: column;
  height: 24px;
  padding: 0;
  box-sizing: border-box;
  cursor: pointer;
}
.toggle-button:focus {
  outline: none;
}
.toggle-button__line {
  width: 30px;
  height: 2px;
  background: #000000;
}
html {
  height: 100%;
}

.side-drawer {
  height: 100%;
  background: white;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  width: 80%;
  z-index: 1200;
  max-width: 400px;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  transition: -webkit-transform 0.3s ease-out 0s;
  transition: transform 0.3s ease-out 0s;
  transition: transform 0.3s ease-out 0s, -webkit-transform 0.3s ease-out 0s;
}
.side-drawer a {
  color: #000000;
  text-decoration: none;
  font-size: 16px;
}

.side-drawer.open {
  transform: translateX(0%);

  transition: transform 0.3s ease-out 0s;
}

.side-drawer ul {
  height: 100%;
  justify-content: center;
  list-style: none;
  display: flex;
  flex-direction: column;
}
.side-drawer li {
  margin: 0.5rem 0;
}
.side-drawer a:hover,
.side-drawer a:active {
  color: var(--Red-color);
}

.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1100;
}
.display {
  transform: translateX(0);
}

@media (min-width: 821px) {
  .toolbar__toggle-button {
    display: none;
  }
  .toolbar__logo {
    margin: 0;
  }
  .side-drawer {
    display: none;
  }
}

.toolbar-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -1px;
  color: #000000;
  transition: 0.3s;
  border-bottom: 3px solid var(--primary-color);
}
.active-toolbar,
.toolbar-heading:hover {
  color: var(--Red-color);
  border-bottom: 3px solid var(--Red-color);
}
.manu-section {
  padding: 106px 0px 80px 0px;
  background-color: var(--primary-color);
  background-image: url("../src/assets/media-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.manu-section-2 {
  padding: 20px 0px 80px 0px;
  background-color: var(--primary-color);
  background-image: url("../src/assets/media-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.manufacturing-text {
  padding: 0px !important;
}
.manufacturing-align {
  text-align: left !important;
}
.manufacturing-stroke {
  left: 0px !important;
}
.manu-slider {
  padding: 48px 0px 0px 0px;
}
.manu-scroller {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
}
.manu-img {
  width: 100%;
  border-radius: 8px;
}
.manufacturing-card {
  width: 100%;
  background-color: var(--primary-color);

  border-radius: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
}
.manufacturing-card .text-img {
  max-height: 600px;
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: 23px;
}

.manufacturing-card .inner-card {
  padding: 40px 32px;
}
.manufacturing-card .inner-card .inner-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: #464646;
  margin-bottom: 0px;
}
.normal-section {
  padding: 0px 0px 80px 0px;
}
.manu-icon-box {
  width: 100%;
  background-color: var(--primary-color);
  border-radius: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px 10px;
}
.manu-icon-box .manu-icon-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  letter-spacing: -0.5px;
  margin-bottom: 0px;
  margin-top: 24px;
  color: var(--secondary-color);
}
.operations-section {
  padding: 80px 0px;
  background-image: url("/src/assets/operations-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.country-center {
  display: flex;
  align-items: center;
  padding: 40px 0px;
}
.countries {
  background-color: var(--primary-color);
  width: 100%;
  padding: 24px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.countries .country-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.5px;
  margin-bottom: 0px;
  margin-top: 24px;
  color: var(--secondary-color);
}
.country-d-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  letter-spacing: -1.5px;
  margin-bottom: 16px;
  color: var(--Red-color);
}
.country-d-info {
  text-align: left;
  margin-bottom: 0px;
  color: var(--secondary-color);
}
.country-d-container {
  display: flex;
  margin-top: 32px;
}
.country-d-img {
  margin: 0px 4px;
}

.dropbtn {
  background-color: #04aa6d;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.dropdown-content a:hover {
  background-color: #ddd;
  color: inherit !important;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}
.work-heading {
  margin-bottom: 0px;
  color: var(--secondary-color);
  margin-bottom: 32px;
  text-align: justify;
}

.research-backshadow {
  display: flex;
  top: 2904px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
}
.company-logo {
  width: 74px;
  height: 74px;
  background: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.company-img {
  width: 61px;
  height: 61px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.group-img {
  width: 100%;
  height: 100%;
}
.research-items {
  display: flex;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  margin-bottom: 88px;
}
.research-items .image-container {
  padding: 0px;
}
.research-items-box {
  padding: 35px;
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
}
.research-items-box .research-name {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: -1.5px;
  margin-bottom: 0px;
  color: var(--gray-color);
}
.research-items-box .research-paragraph {
  margin-top: 30px;
  letter-spacing: -0.5px;
  color: var(--gray800);
  opacity: 0.8;
}
.community-backshadow {
  display: flex;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  margin-bottom: 88px;
}
.department-bg {
  background-image: url("../src/assets/video.gif");
  height: 668px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sector-center {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.sectors-logo {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  background: #ffffff;

  border: 1px solid #cb343b;

  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
}
.bg-white {
  width: 369px;
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
}
.departments-heading-box {
  padding: 8px 30px 8px 24px;
  background: #ffffff;
  width: max-content;
}
.sector-backshadow {
  margin: 24px 0px;
}
.dep-hero-paragraph-box {
  margin-top: 24px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding: 8px 30px 8px 24px;
}
.dep-hero-h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;
  margin-bottom: 0px;
  text-align: center;
  letter-spacing: -2px;
  color: var(--Red-color);
}
.dep-hero-paragraph {
  text-align: center;
  margin-bottom: 0px;
  color: var(--gray-color);
}
.dep-hero-dot {
  color: black;
  position: absolute;
}
.departments-reverse {
  display: flex;
  flex-direction: row-reverse;
}
.research-reverse {
  display: flex;
  flex-direction: row-reverse;
}
.show-overlay {
  opacity: 1;
  z-index: 1002;
}
.hide-overlay {
  opacity: 0;
  z-index: -1;
}
.main-overlay {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.16);
  width: 100%;
  height: 100vh;
  transition: 0.3s;
  align-items: center;
  justify-content: center;
}
.transform100 {
  transform: translateY(-100%);
}
.transform0 {
  transform: translateY(0%);
}
.overlay-ceo {
  width: 150px;
}
.overlay-box {
  background-color: var(--primary-color);
  padding: 30px 20px;
  border-radius: 10px;
  transition: 0.3s;
}
.overlay-top-section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
}
.overlay-info {
  margin-left: 16px;
}
.overlay-info .heading {
  margin-bottom: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -2px;
  color: var(--Red-color);
}
.overlay-info .head-role {
  margin-bottom: 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: -1px;
  color: var(--gray-color);
  opacity: 0.6;
}
.cross-btn {
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 16px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid var(--secondary-color);
  display: flex;
  align-items: center;
  justify-content: center;
}
.qm-align-section {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.qm-section {
  padding: 120px 0px 60px 0px;
}
/* .qm-image-center {
  display: flex;
  align-items: center;
} */
.qm-image {
  width: 100%;
}
.qm-justify-text {
  text-align: justify;
}
.qm-reverse {
  display: flex;
  flex-direction: row-reverse;
}
.qm-box {
  margin-bottom: 60px;
}
.three-dots-box {
  display: flex;
  margin-bottom: 16px;
}
.three-dots-box .dot-circle {
  height: 10px;
  width: 10px;
  background-color: #cb343b;
  border-radius: 50%;
  margin-right: 5px;
  opacity: 0.2;
}
.qm-color {
  color: #4a4a4a !important;
}
.qm-section-heading {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  margin-bottom: 0px;
  text-align: left;
  letter-spacing: -1.5px;
  color: var(--gray-color);
  margin-bottom: 16px;
}

.load-more {
  width: 180px;
  padding: 16px 36px;
  background-color: var(--Red-color);
  color: var(--primary-color);
  border-radius: 10px;
  border: none;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -1px;
}
.round-box {
  border-radius: 50%;
  z-index: 100;
  overflow: hidden;
  width: 130px;
  height: 130px;
}
.dir-paragraph {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: justify;
  letter-spacing: -0.5px;
  margin-bottom: 0px;
  color: var(--gray-color);
}
.social-img1 {
  width: 40px;
}

@media (max-width: 820px) {
  .toolbar_navigation-items {
    display: none;
  }
  .timeline-border::before {
    left: 35px;
  }
  .spot-left {
    left: -40px;
  }
  .spot-right {
    left: -40px;
  }
  .timeline-end {
    justify-content: flex-start;
  }
  .timeline-box {
    width: 100%;
  }
  .timeline-center-date {
    justify-content: flex-start;
    margin-left: 30px;
    width: auto;
  }
  .timeline-founded {
    border-radius: 5px;
  }
  .contact-img {
    display: none;
  }
  .justify-start {
    padding: 32px 0px;
  }
  .community-backshadow {
    display: block;
    margin-bottom: 40px;
  }
  .manu-icon-box {
    margin-bottom: 15px;
  }
  .country-d-img {
    margin: 8px;
    width: 40%;
  }
  .country-d-container {
    flex-wrap: wrap;
    justify-content: center;
  }
  .countries {
    margin-bottom: 20px;
  }

  .community-curved {
    display: none;
  }
  .community-curve-invert {
    display: none;
  }
  .social-img {
    width: 25px;
  }
  .adjust-block {
    width: 70px;
    height: 70px;
    margin-bottom: 16px;
  }
  .place-adjust {
    display: none;
  }
  .place-img {
    margin-bottom: 15px;
  }
  .center {
    flex-direction: column;
  }
  .group-img {
    margin-bottom: 25px;
  }
  .contact-img {
    display: none;
  }
  .more-box {
    flex-direction: column;
  }
  .policy-heading1 {
    margin-top: 5px;
  }
  .rounded-pol {
    width: 120px;
    height: 120px;
  }
  .inside-img {
    width: 55px;
  }
  .landing-hero-img {
    display: none;
  }
  .timeline-full {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
  }
  .spot-left {
    left: -5px;
  }
  .spot-right {
    left: -5px;
  }
  .img-vector3 {
    width: 330px;

    left: 4%;
  }
  .testimonials {
    width: 250px;
  }
  .reverse {
    flex-direction: column-reverse;
  }
}

@media (max-width: 480px) {
  .no-mobile-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .reverse-on-mobile {
    display: flex;
    flex-direction: column-reverse;
  }
  .background-main {
    padding: 64px 0px;
  }
  .landing-family {
    margin-bottom: 32px;
  }
  .overlay-box {
    overflow-y: scroll;
    max-height: 100vh;

    border-radius: 0px;
  }
  .toolbar {
    height: auto;
  }
  .main-center {
    height: auto;
  }
  .main-btn {
    margin-top: 48px;
    width: 100%;
  }
  .mission-paragraph {
    width: 100%;
  }
  .mission-background {
    padding: 64px 0px;
    background-image: none;
  }
  .rotate {
    margin-left: unset;
    transform: rotate(0deg);
  }
  .right-product-info {
    right: unset;
    position: relative;
  }
  .left-product-info {
    left: unset;
    position: relative;
  }
  .box-detail {
    padding: 30px;
    height: auto;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: none;
  }
  .box-margin {
    margin-top: 0px;
  }
  .mission-heading3 {
    text-align: center;
    margin-top: 8px;
    letter-spacing: -1px;
    font-size: 18px;
  }
  .box-img {
    height: 55px;
    width: 55px;
  }
  .product-section {
    height: 250px;
    border-radius: 15px 15px 0px 0px;
  }
  .circle-box {
    height: 200px;
    width: 200px;
  }
  .product-info-box {
    border-radius: 0px;
    padding: 30px 20px;
  }
  .product-info-box .product-heading {
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -1px;
  }
  .therapeutic-bg {
    background-image: none;
  }
  .column-reverse-section {
    display: flex;
    flex-direction: column-reverse;
  }
  .policy-heading1 {
    font-size: 18px;
    letter-spacing: -1px;
    line-height: 120%;
    margin-top: 5px;
  }
  .align-content {
    margin-top: 32px;
  }
  .group-img {
    height: auto;
  }
  .contact-box {
    padding: 30px 20px;
  }
  .product-h2 {
    font-size: 32px;
    line-height: 120%;
  }
  .contact-send-message {
    width: 100%;
  }
  .more-box .contact-area {
    margin: 8px 0px;
  }
  .form {
    margin-bottom: 32px;
  }
  .team-h1 {
    margin-bottom: 32px;
    font-size: 32px;
    line-height: 120%;
    letter-spacing: -1px;
  }
  .history-heading2 {
    font-size: 32px;
    line-height: 120%;
    letter-spacing: -1px;
  }

  .himont-spacebetween {
    flex-direction: column;
  }
  .social-media-icons {
    justify-content: center;
  }
  .main-heading {
    font-size: 40px;
    line-height: 120%;
    letter-spacing: -2px;
  }
  .img-vector4 {
    position: relative;
    left: unset;
    top: unset;
  }
  .mission-heading2 {
    font-size: 32px;
    line-height: 120%;
    letter-spacing: -1px;
  }
  .timeline .spot {
    height: 15px;
    width: 15px;
  }
  .timeline-box {
    width: 95%;
  }
  .timeline-border::before {
    width: 4px;
    left: 17px;
  }
  .department-heading-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dep-hero-h1 {
    font-size: 40px;
    line-height: 120%;
    letter-spacing: -1px;
  }
  .dep-hero-paragraph-box {
    width: auto;
  }
  .research-text-section {
    padding: 40px 0px;
  }
  .community-detail {
    padding: 30px 0px;
  }
  .community-paragraph {
    margin-top: 24px;
    margin-bottom: 30px;
    text-align: justify;
  }
  .community-h1 {
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -1px;
  }
  .uplayer-h1 {
    font-size: 32px;
    line-height: 120%;
    letter-spacing: -1px;
  }
  .uplayer-bg {
    margin: 0px;
    padding: 40px 0px;
    height: auto;
  }
  .follow-bg {
    height: auto;
    padding: 40px 0px;
  }
  .follow-h1 {
    font-size: 32px;
    line-height: 120%;
    margin-top: 0px;
  }
  .operations-section {
    padding: 40px 0px;
  }
  .country-d-name {
    font-size: 32px;
    line-height: 120%;
    letter-spacing: -1px;
  }
  .manu-section {
    padding: 80px 0px 16px 0px;
  }
  .manu-section-2 {
    padding: 20px 0px 64px 0px;
  }
  .manu-slider {
    padding: 40px 0px;
  }
  .c-main-paragraph {
    width: 100%;
    margin-top: 34px;
    margin-bottom: 16px;
    color: var(--gray800);
  }
  .research-items {
    display: block;
    box-shadow: none;
  }
  .research-items-box {
    padding: 20px 10px 0px 10px;
  }
  .research-items-box .research-paragraph {
    margin-bottom: 0px;
    margin-top: 16px;
  }
  .research-items-box .research-name {
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -1px;
  }
  .research-reverse {
    display: block;
  }
  .research-frame {
    left: 10px;
    top: 10px;
  }
  .team-section-spacing {
    padding: 40px 0px;
  }
  .policy-bg {
    padding: 64px 0px 24px 0px;
    background-image: none;
  }
  .history-bg {
    background-image: none;
  }
  .background-head {
    background-image: none;
    padding-bottom: 40px;
  }
  .product-spacing {
    margin-top: 40px;
  }
  .achievement-box .title {
    font-size: 24px;
    margin: 8px 0px;
  }
  .achievement-logo {
    height: 50px;
    width: 50px;
  }
  .achievements {
    padding: 8px;
  }
  .base-paragraph {
    font-size: 14px;
  }
  .timeline-center-date {
    margin-left: 5px;
  }
  .research-second-section {
    padding-top: 40px;
  }
  .product__padding {
    padding-top: 64px;
  }
  .supply-chain-bg {
    padding: 40px 0px;
  }
  .our-products-svg {
    width: 100%;
  }
  .arrow-border {
    width: 60px;
    height: 60px;
    font-size: 24px;
    /* border-radius: 50%; */
  }
  .border-container {
    border: 1px solid var(--Red-color-light);
    padding-top: 60px;
  }
  .country-center {
    flex-direction: column-reverse;
  }
  .country-info-box {
    padding-top: 32px;
  }
  .three-dots-box {
    display: none;
  }
  .qm-box {
    margin-bottom: 24px;
  }
  .qm-section-heading {
    font-size: 24px;
    margin-top: 24px;
    line-height: 120%;
  }
  .role-bg {
    background-image: none;
  }
  .role-h1 {
    font-size: 24px;
    line-height: 120%;
  }
  .align-more {
    flex-direction: row !important;
  }
  .job-bg {
    width: 80%;
    padding: 32px 24px;
  }
  .job-h1 {
    font-size: 24px;
    line-height: 120%;
  }
  .first-container {
    margin-bottom: 0px;
  }
  .overview-h1,
  .responsibility-h1 {
    font-size: 14px;
    line-height: 120%;
  }
  .responsibility-list,
  .overview-paragraph {
    font-size: 14px;
    line-height: 120%;
  }
  .apply-btn {
    width: 100%;
  }
}
